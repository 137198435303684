import { DELIVER, FINISH, RETURNED, WAIT_RELATION, IN_DISPATCH } from '@/utils/constant'

export const selection = { type: 'selection' }
export const orderCode = { label: '订单编号', prop: 'orderCode', width: '150' }
export const orderStatus = { label: '订单状态', prop: 'orderStatus', width: '150' }
export const expressCompanyName = { label: '物流渠道', prop: 'expressCompanyName', width: '100' }
export const expressWaybillCode = { label: '运单号', prop: 'expressWaybillCode', width: '150' }
export const userName = { label: '业务员', prop: 'userName', width: '120' }
export const expressExpireTime = { label: '物流到期时间', prop: 'expressExpireTime', width: '120' }
export const isKnifeLayoutCreated = { label: '刀版图状态', prop: 'isKnifeLayoutCreated', width: '100' }
export const time = { label: '时间', prop: 'time', width: '160' }
export const importTime = { label: '导入时间', prop: 'importTime', width: '160' }
export const deliveryTime = { label: '发货时间', prop: 'deliveryTime', width: '160' }
export const returnCompleteTime = { label: '退货时间', prop: 'returnCompleteTime', width: '160' }
export const orderType = { label: '订单类别', width: '100' }
export const menu = { label: '操作', width: '250' }

export const tableFormDataObj = {
  '': [
    selection,
    orderCode,
    orderStatus,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    isKnifeLayoutCreated,
    time,
    menu
  ],
  [DELIVER]: [
    selection,
    orderCode,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    isKnifeLayoutCreated,
    time,
    menu
  ],
  [FINISH]: [
    selection,
    orderCode,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    isKnifeLayoutCreated,
    time,
    menu
  ],
  [RETURNED]: [
    selection,
    orderCode,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    isKnifeLayoutCreated,
    time,
    menu
  ],
  [WAIT_RELATION]: [
    selection,
    orderCode,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    time,
    menu
  ],
  default: [
    selection,
    orderCode,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    isKnifeLayoutCreated,
    time,
    menu
  ],
  [IN_DISPATCH]: [
    selection,
    orderCode,
    expressCompanyName,
    expressWaybillCode,
    userName,
    expressExpireTime,
    isKnifeLayoutCreated,
    time,
    orderType,
    menu
  ]
}

//待关联列表
export const waitRelationTableFormData = [
  { type: 'selection' },
  { label: '订单编号', prop: 'orderCode', width: '150' },
  { label: '物流渠道', prop: 'expressCompanyName', width: '100' },
  { label: '运单号', prop: 'expressWaybillCode', width: '150' },
  { label: '业务员', prop: 'userName', width: '100' },
  { label: '物流到期时间', prop: 'expressExpireTime', width: '120' },
  { label: '导入时间', prop: 'importTime', width: '160' },
  { label: '发货时间', prop: 'deliveryTime', width: '160' },
  { label: '操作', width: '250' }
]
