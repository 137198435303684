var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line inline-block",staticStyle:{"margin-right":"15px","margin-left":"10px"}},[_vm._t("default",[_c('el-button',{attrs:{"uiid":"zd-selectExpress","size":"mini"},on:{"click":_vm.to}},[_vm._v(" 选择物流方式 ")])]),(_vm.dialogVisible)?_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"visible":_vm.dialogVisible,"uiid":"zd-selectExpress-dialog","title":"选择物流方式","width":"800px","top":"10vh","append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[[_c('avue-form',{ref:"form",attrs:{"option":_vm.formOption,"dic":_vm.dic},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('avue-crud',{ref:"avueCrud",attrs:{"data":_vm.domData,"option":_vm.tableOption,"page":_vm.tablePage},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"consigneeCountryCode",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.externalOrderLogistics.consigneeCountryCode)+" ")]}},{key:"consigneeName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.externalOrderLogistics.consigneeName)+" ")]}},{key:"totalWeight",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.externalOrderProduct.totalWeight || '暂无')+" ")]}},{key:"totalVolume",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.externalOrderProduct.totalVolume || '暂无')+" ")]}}],null,false,3692704553)})]],2),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"uiid":"zd-selectExpress-cancel"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"uiid":"zd-selectExpress-confirm","type":"primary","loading":_vm.loading},on:{"click":_vm.onsubmit}},[_vm._v("确定")])],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }