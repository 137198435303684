<template>
  <div v-loading="tableLoading" class="app-container flex-one-page">
    <div class="wrapper">
      <div v-scrollTop>
        <orderState
          v-if="!isDeleted"
          ref="orderState"
          :query="query"
          :sup_this="sup_this"
          @updateCheck="updateCheck"
          @takeStatus="takeStatus"
        ></orderState>
        <formPart
          v-if="permission.search"
          ref="form"
          :query="query"
          :orderStatus="orderStatus"
          :sup_this="sup_this"
          :postData="postData"
          :inDispatch="false"
          :isDeleted="isDeleted"
        ></formPart>
        <div class="flex-middle">
          <loadingBtn
            class="line"
            type="primary"
            size="small"
            @click="downloadPdf"
            v-if="!isDeleted && finalPermission.downloadPdf"
            >下载面单</loadingBtn
          >
          <template v-if="orderStatus === '' || isDeleted">
            <el-dropdown trigger="click" @command="orderExport" v-if="finalPermission.export">
              <el-button type="primary" size="small">
                订单导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="select">导出勾选订单</el-dropdown-item>
                <el-dropdown-item command="all">导出全部订单</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <loadingBtn
              v-if="finalPermission.exportDeclarationInfo"
              type="primary"
              size="small"
              @click="exportDeclarationInfo"
              >导出报关信息</loadingBtn
            >
          </template>
          <BatchApplyWaybill
            v-if="!isDeleted"
            :orderStatus="orderStatus"
            :data="factoryData"
            :sup_this="sup_this"
            v-p="['externaladmin:orderManage:orderList:all:batchApplyWaybill']"
          />
        </div>
        <tablePart
          ref="table"
          class="flex-one"
          :sup_this="sup_this"
          :orderListData="data"
          :orderStatus="orderStatus"
          :permission="finalPermission"
          :isWaybillCreate="true"
          @takeFactory="takeFactory"
        ></tablePart>
      </div>
      <div class="hidden_block"></div>
      <div class="pagination_wrapper">
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </div>
    </div>
  </div>
</template>

<script>
import orderState from './module/orderListModule/orderState'
import formPart from './module/orderListModule/formPart'
import tablePart from './module/orderListModule/tablePart2'
import pagination from './module/orderListModule/pagination'
import BatchApplyWaybill from './module/orderListModule/batchApplyWaybill'
import { orderExport } from '@/views/order/utils'

import saveAs from 'jszip/vendor/FileSaver.js'
import { initDataMixin } from '@/mixins'
import { downloadWaybillFile, exportDeclarationInfo } from '@/api/order/orderListData'
import { getValueFromObj, checkPermission } from '@/utils'
import { DELETED, ORDER_IS_DELETED_YES } from '@/utils/constant'
import { deepClone } from '@/components/avue/utils/util'
import handleSearchFormProps from '@/views/order/utils/handleSearchFormProps'

let deletedOption = {
  label: '已删除',
  value: DELETED,
  searchValue: {
    orderStatus: DELETED
  },
  permission: checkPermission('externaladmin:orderManage:deleted'),
  childPermission: {
    export: checkPermission('externaladmin:orderManage:deleted:export'),
    search: checkPermission('externaladmin:orderManage:deleted:search')
  }
}

export default {
  name: 'orderOrderList',
  mixins: [initDataMixin],
  components: {
    orderState,
    formPart,
    tablePart,
    BatchApplyWaybill, //批量申请运单号

    pagination
  },
  data() {
    const merge = this.$route.path.split('/').pop() !== 'deleted' ? { isGetAllOrder: 1 } : {}
    return {
      sup_this: this,
      method: 'post',
      initBool: false,
      orderStatus: '0',
      factoryData: [],
      permission: {},

      mergeData: Object.assign(
        {
          orderItems: [
            {
              asc: false,
              column: 'order_time'
            },
            {
              asc: false,
              column: 'id'
            }
          ],
          isFormatChildName: 1,
          isReturnRelatedData: 1,
          isReturnWaybillChangeRecord: 1
        },
        merge
      ),
      postData: {}
    }
  },
  computed: {
    isDeleted() {
      return this.$route.path.split('/').pop() === 'deleted'
    },

    url({ isDeleted }) {
      return '/externaladmin/orderService/externalOrder/orderList'
    },
    handleSearchFormProps() {
      return {
        ...handleSearchFormProps,
        orderStatus: (val) => {
          if (val == DELETED) {
            return {
              $isMergeData: true,
              isDeleted: ORDER_IS_DELETED_YES
            }
          }
          return val
        }
      }
    },
    finalPermission({ permission, isDeleted }) {
      if (isDeleted) {
        return deletedOption.childPermission
      }
      return permission
    }
  },
  watch: {
    isDeleted: {
      handler(n) {
        if (n) {
          Object.assign(this.query, deletedOption.searchValue)
          this.init()
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.permission = (this.isDeleted ? deletedOption : this.$refs.orderState).childPermission || {}
    if (!this.permission) {
      this.$message.error('页面无权限！')
    }
  },
  methods: {
    beforeInitData() {
      this.postData = this.params
    },
    initCallBack(res) {
      // console.log('ressssssss', res)
      let tempArr = []
      this.data.forEach((item) => {
        tempArr.push({
          isHeader: true,
          orderCode: item.orderCode,
          userName: item.userName,
          orderInfo: item
        })
        item.$sortTime = this.getSortTime(item)
        item.$lastTime = item.$sortTime[item.$sortTime.length - 1]
        tempArr.push(item)
      })
      this.data = tempArr
    },
    getSortTime(item) {
      let times = [
        {
          label: '导入时间',
          value: item.orderTime,
          prop: 'orderTime'
        },
        {
          label: '面单下载时间',
          value: item.expressWaybillDownloadTime,
          prop: 'expressWaybillDownloadTime'
        },
        {
          label: '取消时间',
          value: item.cancelTime,
          prop: 'cancelTime'
        },
        {
          label: '截单时间',
          value: item.cutoffTime,
          prop: 'cutoffTime'
        },
        {
          label: '排单时间',
          value: item.sendTime,
          prop: 'sendTime'
        },
        {
          label: '发货时间',
          value: item.deliveryTime,
          prop: 'deliveryTime'
        },
        {
          label: '退货申请时间',
          value: item.returnApplyTime,
          prop: 'returnApplyTime'
        },
        {
          label: '退货完成时间',
          value: item.returnCompleteTime,
          prop: 'returnCompleteTime'
        }
      ]
      if (item.productionTime) {
        times.push({
          label: '生产时间',
          value: item.productionTime,
          prop: 'productionTime'
        })
      }
      return times
        .filter((time) => time.value)
        .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime())
    },
    takeStatus(data) {
      this.orderStatus = data
    },
    takeFactory(data) {
      this.factoryData = data
      // console.log(this.factoryData)
      console.log(this.factoryData)
    },
    async downloadPdf() {
      /*if (!await this.getSelectionDataAllArr()) return
       if (this.selectionDataAllArr.length === 0) {
       this.$message.warning('请先选择订单')
       return
       }*/
      if (this.factoryData.length === 0) {
        return this.$message.warning('请先选择订单')
      }

      let res = await awaitFormResolve(
        downloadWaybillFile({
          idList: this.factoryData.map((item) => item.orderInfo.id)
        })
      )
      if (!res) return
      try {
        let text = JSON.parse(await res.text())
        if (/1001000/.test(text.code)) {
          await this.init()
          return this.$message.warning(text.message)
        }
      } catch (e) {}

      saveAs(res, '下载面单')
      this.$message.success('下载成功')
      await this.init()
    },
    async orderExport(type) {
      if (type === 'select' && this.factoryData.length === 0) return this.$message.warning('请先选择订单')
      return await orderExport(type === 'select' ? this.factoryData : [], this, this.params)
    },
    async exportDeclarationInfo() {
      await this.beforeInit()
      let { params } = this
      let query = deepClone(params)
      query.idList = this.changeLogistics()
      let res = await awaitFormResolve(exportDeclarationInfo(query))
      console.log(res)
      if (res) {
        let date
        let time = getValueFromObj(this.$refs, 'form.time')
        if (Array.isArray(time) && time.length) {
          date = `${time[0]}--${time[1]}`
        } else {
          date = this.$moment().format('YYYY-MM-DD')
        }
        saveAs(res, `${date}-报关信息`)
        this.$message.success('导出成功')
      } else {
        this.$message.warning('无报关信息可导出，请输入正确数值后重试')
      }
    },
    updateCheck(newCheck) {
      this.permission = newCheck
    },
    changeLogistics() {
      const newArr = this.factoryData.map(({ id }) => {
        return id
      })
      return newArr
      // console.log('newArr', newArr)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  overflow: hidden;
  padding: 20px 0 95px 15px;
  position: relative;
  .wrapper {
    height: 100%;
    overflow: auto;
    border-bottom: 1px solid #ebeef5;
    padding-right: 15px;
  }
  .pagination_wrapper {
    position: fixed;
    bottom: 32px;
    right: 45px;
    // background-color: rgb(25, 224, 158);
    z-index: 10;
  }
  .hidden_block {
    position: absolute;
    bottom: 95px;
    right: 0;
    width: 23px;
    height: 1px;
    background-color: #fff;
  }
  ::v-deep {
    .el-table {
      overflow: hidden;
    }
    .list-state-group .el-radio-button__inner {
      width: 135px;
    }
  }
}
</style>
