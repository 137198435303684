<template>
  <!-- 批量申请运单号 -->
  <selectExpressCompanyName ref="dialog" :tableData="tableData" :isBatch="true" :class="[isHidden && 'none']">
    <el-button uiid="zd-batchApplyWaybill" size="small" type="primary" @click="showDialog">批量换单</el-button>
  </selectExpressCompanyName>
</template>

<script>
import selectExpressCompanyName from './selectExpressCompanyName'

import {
  WAYBILL_TYPE_SYS,
  WAYBILL_TYPE_NO,
  IN_PRODUCTION,
  EXPRESS_WAYBILL_CREATE_ERROR,
  getLabelOfOrderStatus
} from '@/utils/constant'
import { isOldFactoryOrder } from '@/views/order/utils/validate'
import { map } from 'lodash'

//可以申请运单号的状态
const CAN_APPLY_WAYBILL_LIST = [IN_PRODUCTION, { orderStatus: IN_PRODUCTION, expressWaybillIsCreated: EXPRESS_WAYBILL_CREATE_ERROR }].map(
  (orderStatus) => getLabelOfOrderStatus(typeof orderStatus === 'object' ? orderStatus : {orderStatus})
)

export default {
  components: {
    selectExpressCompanyName
  },
  props: {
    data: {
      type: Array,
      required: true
    },

    orderStatus: {
      required: true
    },

    sup_this: {
      type: Object,
      required: true
    }
  },
  computed: {
    tableData() {
      const data = this.sup_this.factoryData.map((item) => {
        const { orderInfo } = item
        return Object.assign(item, orderInfo)
      })
      return data
    },

    //是否隐藏
    isHidden({ orderStatus }) {
      return (
        [...CAN_APPLY_WAYBILL_LIST, '', '0'].find((applyOrderStatus) => {
          return applyOrderStatus == orderStatus
        }) == null
      )
    }
  },

  methods: {
    showDialog() {
      if (!this.data.length) {
        this.$message.warning('请先选择订单')
        return
      }

      const isSystemFedex = this.data.every(
        ({ waybillType, orderInfo, orderType }) =>
          ((waybillType || orderInfo.waybillType) === WAYBILL_TYPE_SYS ||
            (waybillType || orderInfo.waybillType) === WAYBILL_TYPE_NO) &&
          (orderType || orderInfo.orderType) == 1
      )

      if (!isSystemFedex) {
        this.$message.warning('只有普通订单且是系统运单才能批量申请')
        return
      }
      //是否存在非法订单
      const isPass = this.data.every((order) =>
        CAN_APPLY_WAYBILL_LIST.find((status) => status === getLabelOfOrderStatus(order.orderStatus ? order : order.orderInfo))
      )

      if (!isPass) {
        this.$message.warning(`只有${CAN_APPLY_WAYBILL_LIST.join('、')}的订单才能进行换单操作!`)
        return
      }

      const oldFactoryOrderList = this.data.filter(isOldFactoryOrder)
      if (oldFactoryOrderList.length && oldFactoryOrderList.length !== this.data.length) {
        this.$message.warning(
          `${map(oldFactoryOrderList, 'orderCode').slice(0, 5).join('、')}等订单无法同时申请物流`
        )
        return
      }

      this.$confirm(`<div>确定申请这些订单吗？`, '提醒', {
        confirmButtonText: '确认申请',
        cancelButtonText: '放弃',
        dangerouslyUseHTMLString: true,
        customClass: 'uiid-zd-batchApplyWaybill',
        cancelButtonClass: 'uiid-zd-batchApplyWaybill-cancel',
        confirmButtonClass: 'uiid-zd-batchApplyWaybill-confirm',
        callback: async (action) => {
          if (action === 'confirm') {
            this.$refs.dialog.to()
          } else {
            this.$message.info('已放弃')
          }
        }
      })
    }
  }
}
</script>

<style></style>
