<template>
  <div class="line inline-block" style="margin-right: 15px; margin-left: 10px">
    <slot>
      <el-button uiid="zd-selectExpress" size="mini" @click="to"> 选择物流方式 </el-button>
    </slot>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      uiid="zd-selectExpress-dialog"
      title="选择物流方式"
      width="800px"
      top="10vh"
      append-to-body
      v-loading="loading"
    >
      <div>
        <template>
          <avue-form ref="form" v-model="form" :option="formOption" :dic="dic"></avue-form>
          <avue-crud
            ref="avueCrud"
            :data="domData"
            :option="tableOption"
            :page="tablePage"
            @size-change="sizeChange"
            @current-change="pageChange"
            @selection-change="selectionChange"
          >
            <template #consigneeCountryCode="{ row }">
              {{ row.externalOrderLogistics.consigneeCountryCode }}
            </template>

            <template #consigneeName="{ row }">
              {{ row.externalOrderLogistics.consigneeName }}
            </template>

            <template #totalWeight="{ row }">
              {{ row.externalOrderProduct.totalWeight || '暂无' }}
            </template>
            <template #totalVolume="{ row }">
              {{ row.externalOrderProduct.totalVolume || '暂无' }}
            </template>
          </avue-crud>
        </template>
      </div>
      <span slot="footer">
        <el-button uiid="zd-selectExpress-cancel" @click="cancel">取消</el-button>
        <el-button uiid="zd-selectExpress-confirm" type="primary" @click="onsubmit" :loading="loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { EXPRESS_WAYBILL_CREATED, EXPRESS_WAYBILL_PRINTED, EXPRESS_WAYBILL_CREATE_ERROR } from '@/utils/constant'
import { dialogComponentsMixin } from '@/mixins'
import { validatenull } from '@/components/avue/utils/validate'
import { orderExpressBatchUpdate, getFreight, getFreightBySupply } from '@/api/expressAccount'
import { isOldFactoryOrder } from '@/views/order/utils/validate'
import { formOption1, formOption2 } from './const'
import { mapGetters } from 'vuex'
import { deepClone } from '@/components/avue/utils/util'
import { intersection } from 'lodash'

export default {
  mixins: [dialogComponentsMixin],

  props: {
    sup_this: {
      type: Object,
      default: null
    },
    tableData: {
      type: Array,
      default: () => []
    },
    isBatch: {
      type: Boolean,
      default: false
    },
    singleData: {
      tpe: Object,
      default: () => []
    }
  },

  data() {
    return {
      form: {},
      formOption1,
      formOption2,
      selectionData: [],
      tableOption: {
        menu: false,
        selection: true,
        reserveSelection: true,
        rowKey: 'id',
        column: [
          {
            label: '订单号',
            prop: 'orderCode'
          },
          {
            label: '收件国家',
            prop: 'consigneeCountryCode',
            slot: true
          },
          {
            label: '收件人',
            prop: 'consigneeName',
            slot: true
          },
          {
            label: '总重量',
            prop: 'totalWeight',
            slot: true
          },
          {
            label: '总体积',
            prop: 'totalVolume',
            slot: true
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ]
      },
      tablePage: {
        pageIndex: 1,
        pageSize: 10,
        total: 1
      },
      accountId: null,
      loading: false,
      channelData: [],
      tableDataCopy: [],
      dic: {}
    }
  },

  computed: {
    ...mapGetters(['expressCompanyId']),

    /**
     * @description: 将数据分页展示
     * @param {Array} tableData
     * @param {Object} tablePage
     * @param {*} pageSize
     * @return {Array} 当前页的数据，跟pageSize和tableData
     */
    domData({ tableDataCopy, tablePage: { pageIndex, pageSize } }) {
      if (this.isBatch) {
        this.tablePage.total = tableDataCopy.length //初始化数据总数
        return tableDataCopy.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
      } else return this.singleData
    },
    formOption() {
      return this.formOption2
    },
    notSelectExpress() {
      return !this.domData.some((item) =>
        [EXPRESS_WAYBILL_CREATED, EXPRESS_WAYBILL_PRINTED, EXPRESS_WAYBILL_CREATE_ERROR].includes(
          item.expressWaybillIsCreated
        )
      )
    }
  },
  watch: {
    channelData: {
      handler(n) {
        this.$set(this.dic, 'expressCompanyId', n)
      },
      immediate: true,
      deep: true
    },

    // 选择物流公司后，获取物流渠道列表
    'form.expressCompanyId'(companyId, o) {
      if (!validatenull(companyId) && companyId !== o) {
        this.form.shippingMethodId = ''
        let company = this.channelData.find((channel) => channel.id === companyId)
        this.$set(this.dic, 'shippingMethodId', company.children)
      }
      if (validatenull(companyId)) {
        this.$set(this.dic, 'shippingMethodId', [])
      }
    },

    'form.shippingMethodId'(shippingMethodId, o) {
      if (!validatenull(shippingMethodId) && shippingMethodId !== o) {
        let shippingMethod = this.dic.shippingMethodId
        const account = (shippingMethod || []).find((item) => {
          return item.id === shippingMethodId
        })
        this.accountId = account?.accountId
      }
    }
  },

  methods: {
    cancel() {
      this.dialogVisible = false
      this.$emit('cancel')
    },

    /**
     * @description: 展示弹窗
     * @param {*}
     * @return {*}
     */
    async to() {
      if (this.isBatch) {
        if (validatenull(this.tableData)) {
          this.$message.warning('请先选择数据')
          return
        }
        if (this.existDelProduct()) {
          this.$message.warning('存在订单产品已删除，请重新关联产品后申请运单号。')
          return
        }
      }

      // 直接使用this.tableData的值作为avue-crud的data会导致外面的表格选中项被置空：原因未知？？？
      this.tableDataCopy = this.isBatch ? this.tableData : this.singleData

      this.dialogVisible = true
      this.loading = true
      let { channelData } = await this.getFreight(this.tableDataCopy)
      this.channelData = channelData

      console.log('选中的数据', this.tableDataCopy)
      this.$nextTick(async () => {
        //默认 选中所有数据
        this.setChecked(this.tableDataCopy)
        this.loading = false

        this.$refs.form.resetForm()
      })
    },

    /**
     * @description: 判断订单中是否存在已经删除的产品
     * @param {*}
     * @return {Boolean}
     */
    existDelProduct() {
      return this.tableData.some((item) => {
        return (item.orderItemDTOList || []).some((orderItem) => {
          return orderItem.productIsDeleted === 1
        })
      })
    },

    selectionChange(val) {
      //设置延迟，在pageChange方法中还可以拿到selectionData
      this.$nextTick(() => {
        this.selectionData = val
      })
    },

    /**
     * @description: 切换页面
     * @param {Number} e 当前页码
     * @return {*}
     */
    pageChange(e) {
      this.tablePage.pageIndex = e
      this.setChecked(this.getSelectedTableData(this.selectionData))
    },

    /**
     * @description: 切换每页条数
     * @param {Number} e 当前页面条数
     * @return {*}
     */
    sizeChange(e) {
      this.tablePage.pageIndex = 1
      this.tablePage.pageSize = e
      this.setChecked(this.getSelectedTableData(this.selectionData))
    },

    /**
     * @description: 获取选中的数据
     * @param {Array} data
     * @return {Array}[]
     */
    getSelectedTableData(data) {
      const tmpArr = []
      this.tableDataCopy.map((item) => {
        const bool = data.some(({ id }) => {
          return id == item.id
        })
        if (bool) {
          tmpArr.push(item)
        }
      })
      return tmpArr
    },

    /**
     * @description: 表格打钩
     * @param {Array} data
     * @return {*}
     */
    setChecked(data) {
      //设置延迟，在vue渲染完成之后调用。
      setTimeout(() => {
        this.$refs.avueCrud.toggleSelection(data, true)
      }, 0)
    },

    async onsubmit() {
      let [err, valid] = await awaitWrap(this.$refs.form.validate())
      if (valid) {
        if (validatenull(this.selectionData)) {
          this.$message.warning('请先选择数据')
          return
        }
        this.loading = true
        const orderIdList = this.selectionData.map(({ id }) => {
          return id
        })
        let [err, res] = await awaitWrap(
          orderExpressBatchUpdate({
            companyId: this.form.expressCompanyId,
            shippingMethodId: this.form.shippingMethodId,
            orderIdList,
            accountId: this.accountId
          })
        )
        if ($SUC(res)) {
          let detail = res.detail
          let type = 'success'
          let message = '运单生成中'
          if (Array.isArray(detail) && detail.length) {
            type = 'error'
            message = ''
            detail.forEach((item) => {
              message += `<div>${item.message}</div>`
            })
          }
          this.$message({
            type,
            message,
            dangerouslyUseHTMLString: true
          })
        }
        this.sup_this && this.sup_this.init()
        this.dialogVisible = false
        this.loading = false
      }
    },

    async getFreight(list = this.allSelectableData) {
      list = this.getPackList(list)
      if (!list) return {}

      let orderIdList = []
      list.forEach((item) => {
        orderIdList.push(item.id)
      })
      let freightData = await awaitResolveDetail(
        (list.some(isOldFactoryOrder) ? getFreightBySupply : getFreightBySupply)({ orderIdList })
      )

      let detail = []
      let freightObj = {}
      freightData?.forEach((item) => {
        let methodList = item.methodList || []
        freightObj[item.orderId] = methodList

        let methodVOList = []
        methodList.forEach((company) => {
          company.children?.forEach((cn) => {
            methodVOList.push({
              id: cn.id,
              freight: cn.totalFreight
            })
          })
        })
        detail.push({
          orderId: item.orderId,
          methodVOList
        })
      })

      return {
        detail,
        freightObj,
        freightData,
        channelData: this.getFreightChannel(list, freightObj)
      }
    },
    getFreightChannel(tableData, orderFreightMethods) {
      if (validatenull(tableData) || validatenull(orderFreightMethods)) return []

      // 各个订单有运费的物流渠道id
      let shippingMethodIds = []
      tableData.forEach(({ id }) => {
        let tempArr = []
        orderFreightMethods[id]?.forEach((company) => {
          company.children?.forEach((shippingMethod) => {
            tempArr.push(shippingMethod.id)
          })
        })
        shippingMethodIds.push(tempArr)
      })
      // console.log(shippingMethodIds)
      // 取物流渠道id交集，保证所选物流渠道对于各个订单都有运费
      shippingMethodIds = intersection(...shippingMethodIds)
      // console.log(shippingMethodIds)

      return deepClone(orderFreightMethods[tableData[0].id] || []).filter((company) => {
        company.children = company.children?.filter((cItem) => shippingMethodIds.includes(cItem.id))
        return company.children?.length
      })
    },
    getPackList(list) {
      if (validatenull(list)) return
      return Array.isArray(list) ? list : [list]
    }
  }
}
</script>
