var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePart"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],ref:"table",attrs:{"uiid":"zd-table","data":_vm.finalData,"border":"","span-method":_vm.spanMethod,"cell-class-name":_vm.tableCellClassName,"row-class-name":_vm.rowClassName},on:{"selection-change":_vm.selectionChange}},[_c('el-table-column',{attrs:{"type":"selection","selectable":_vm.selectable}}),_c('el-table-column',{attrs:{"label":"订单信息","prop":"orderCode","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.$isTotal)?[_vm._v(" 已选中订单数量: "+_vm._s(_vm.selectionTotal)+" ")]:(row.isHeader)?[_c('div',{staticClass:"flex-between"},[_c('div',[_c('span',{attrs:{"uiid":"zd-orderCode"}},[_vm._v(" 订单号："),_c('hoverCopy',{staticClass:"text-primary",attrs:{"value":row.orderCode}},[_vm._v(_vm._s(row.orderCode))])],1),_c('span',{staticClass:"ml20"},[_vm._v("分销商："+_vm._s(row.orderInfo.nickName || '暂无')+" "),(row.orderInfo.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]),(row.orderInfo.childrenUserName)?_c('span',{staticClass:"ml20"},[_vm._v("子账号："+_vm._s(row.orderInfo.childrenUserName || '暂无')),(row.orderInfo.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]):_vm._e()]),(!_vm.getExpressWaybillFilePath(row.orderInfo))?_c('span',[_vm._v("无面单")]):(row.orderInfo.expressWaybillDownloadTime != null)?_c('el-button',{attrs:{"type":"warning","size":"mini"}},[_vm._v("已下载面单")]):_c('span',[_vm._v("未下载面单")])],1)]:[_c('productInfo',{attrs:{"data":row.orderItemDTOList,"orderData":row,"doLayout":_vm.doLayout,"sup_this":_vm.sup_this}})]]}}])}),_c('el-table-column',{attrs:{"label":"物流信息","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.orderType !== _vm.ORDER_TYPE_FBA || (row.orderType === _vm.ORDER_TYPE_FBA && row.freightPaymentTime))?[(row.expressWaybillIsCreated === 8)?[_c('span',{staticStyle:{"color":"red"}},[_vm._v("运单申请中")])]:(
              row.expressWaybillIsCreated === 9 ||
              (("" + (row.orderStatus)) === _vm.EXPIRED && row.waybillType === _vm.WAYBILL_TYPE_SYS)
            )?[_c('div',[_vm._v("运单申请异常")]),_c('span',[_vm._v("物流:")]),_vm._v(" "+_vm._s(_vm.expressName(row))+" "),_c('br'),_c('span',[_vm._v("失败原因:")]),_vm._v(" "+_vm._s(_vm.errorInfo(row))+" "),_c('br')]:[(row.waybillType === _vm.WAYBILL_TYPE_HAND)?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"外部导入","placement":"top"}},[_c('el-button',{attrs:{"type":"warning","size":"mini"}},[_vm._v(" "+_vm._s(_vm.expressName(row))+" ")])],1),(row.changeRecordDTOList && row.changeRecordDTOList.length)?_vm._l((row.changeRecordDTOList),function(ref){
            var expressWaybillCodeOld = ref.expressWaybillCodeOld;
            var id = ref.id;
return _c('div',{key:id},[_c('del',{staticStyle:{"color":"rgb(56, 65, 219)"}},[_vm._v(_vm._s(expressWaybillCodeOld))])])}):_vm._e()]:_c('div',[_vm._v(" "+_vm._s(_vm.expressName(row))+" "),(row.changeRecordDTOList && row.changeRecordDTOList.length)?_vm._l((row.changeRecordDTOList),function(ref){
            var expressWaybillCodeOld = ref.expressWaybillCodeOld;
            var id = ref.id;
return _c('div',{key:id},[_c('del',{staticStyle:{"color":"rgb(56, 65, 219)"}},[_vm._v(_vm._s(expressWaybillCodeOld))])])}):_vm._e()],2),(_vm.getExpressWaybillCode(row))?_c('div',{staticStyle:{"color":"rgb(56, 65, 219)"}},[_c('hoverCopy',{staticStyle:{"color":"#3841db","text-indent":"-8px"},style:({ color: row.orderStatus === 29 ? '#606266' : '#3841db' }),attrs:{"value":_vm.getExpressWaybillCode(row)}},[_vm._v("「"+_vm._s(_vm.getExpressWaybillCode(row))+"」")])],1):_vm._e()],_c('div',[(
                ((row.orderStatus === 42 &&
                  _vm.isShow('externaladmin:orderService:order:tryWaybillNoCreate:inProduct')) ||
                  (row.orderStatus === 29 && _vm.isShow('externaladmin:orderService:order:tryWaybillNoCreate:expired')) ||
                  (row.expressWaybillIsCreated === 0 &&
                    _vm.isShow('externaladmin:orderManage:orderList:waitCreateWaybill:expressWaybillIsCreated0')) ||
                  (row.expressWaybillIsCreated === 9 &&
                    _vm.isShow('externaladmin:orderManage:orderList:waitCreateWaybill:expressWaybillIsCreated9'))) &&
                (row.waybillType === _vm.WAYBILL_TYPE_SYS || row.waybillType === _vm.WAYBILL_TYPE_NO) &&
                !_vm.isDeleted(row) &&
                !_vm.isFbaOrder(row)
              )?_c('selectExpressCompanyName',{ref:"dialog",attrs:{"sup_this":{ selectionDataAllArr: [row], init: _vm.sup_this.init },"singleData":[_vm.singleData]}},[_c('el-button',{attrs:{"uiid":"zd-selectExpress","size":"small"},on:{"click":function($event){return _vm.showDialog(row)}}},[_vm._v("重新申请")])],1):_vm._e()],1)]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"支付信息","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('div',[_c('p',[_vm._v("货款："+_vm._s(_vm.getPaymentProduct(row)))]),(row.freightPaymentTime && row.orderType == _vm.fbaOrder)?_c('p',[_vm._v("运费："+_vm._s(_vm.getTemplatePrice(row)))]):_vm._e(),(row.orderType != _vm.fbaOrder)?_c('p',[_vm._v("运费："+_vm._s(_vm.getTemplatePrice(row)))]):_vm._e(),_c('p',[_vm._v("总计："+_vm._s(_vm.totalPrice(row) || '0'))])])]}}])}),(_vm.orderStatus === _vm.DELIVER)?_c('el-table-column',{attrs:{"label":"包裹重量","prop":"sendOutWeight","formatter":_vm.sendOutWeightFormatter}}):_vm._e(),_c('el-table-column',{attrs:{"label":"订单状态","formatter":_vm.getLabelOfOrderStatus}}),_c('el-table-column',{attrs:{"label":"是否删除","prop":"isDeleted","formatter":_vm.isDeletedFormatter}}),_c('el-table-column',{attrs:{"label":"订单类别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var orderType = ref.row.orderType;
return [_vm._v(" "+_vm._s(_vm.findByvalue(_vm.ORDER_TYPE_DIC, orderType))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"是否是购物车订单"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var isCartOrder = ref.row.isCartOrder;
return [_vm._v(" "+_vm._s(_vm.findByvalue(_vm.CART_ORDER_DIC, isCartOrder))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"物流到期时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [(row.orderType !== _vm.ORDER_TYPE_FBA)?[(parseInt(row.expressExpireTime) <= 3)?_c('div',[_vm._v(" "+_vm._s(row.expressExpireTime)+" "),_c('el-tag',{staticClass:"tixing",attrs:{"type":"danger","size":"mini"}},[_vm._v("临近提醒")])],1):_c('div',[_vm._v(" "+_vm._s(row.expressExpireTime)+" ")])]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((row.$sortTime),function(time){return _c('div',{key:time.prop,staticClass:"icon-circle"},[_vm._v(" "+_vm._s(time.label)+"："+_vm._s(time.value)+" ")])})},proxy:true}],null,true)},[(row.$lastTime)?_c('div',[_vm._v(_vm._s(row.$lastTime.label)+"："+_vm._s(row.$lastTime.value))]):_vm._e()]),(
            row.orderType !== _vm.ORDER_TYPE_FBA &&
            (row.waybillChangeStatus == _vm.IS_WAYBILL_CHANGE_YES ||
              row.waybillChangeStatus == _vm.IS_WAYBILL_CHANGE_BACKEND_YES)
          )?_c('div',[_c('div',[_vm._v("换单时间："+_vm._s(_vm.parseTime(row.changeTime) || '暂无'))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return (!_vm.isDeleted(scoped.row))?[(_vm.permission.update)?_c('changeStateBtn',{attrs:{"data":scoped.row,"sup_this":_vm.sup_this}}):_vm._e(),(_vm.permission.edit && _vm.canEdit(scoped.row))?_c('Edit',{attrs:{"data":scoped.row,"type":1,"id":scoped.row.id,"sup_this":_vm.sup_this,"orderStatus":_vm.orderStatus}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.permission.detail)?_c('Edit',{attrs:{"data":scoped.row,"isProTable":true,"id":scoped.row.id}},[_vm._v(" 查看详情 ")]):_vm._e(),(_vm.isShowPrintExpressInvoice(scoped.row))?_c('PrintExpressInvoice',{staticClass:"line",attrs:{"data":scoped.row,"id":scoped.row.id}}):_vm._e()]:undefined}}],null,true)}),_c('template',{slot:"empty"},[_vm._v("暂无数据")])],2),_c('viewKnifeLayout',{attrs:{"visible":_vm.dialogVisible,"data":_vm.dialogData},on:{"update:visible":function($event){_vm.dialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }