/* eslint-disable */
import { EXPRESS_WAYBILL_CREATE_ERROR, EXPRESS_WAYBILL_CREATING, getExpressWaybillCode } from '@/utils/constant'

export function showWaybillCode(row) {
  return !!(getExpressWaybillCode(row) || row.expressWaybillCode || row.expressServiceCode)
}

export function isWaybillApplying({ expressWaybillIsCreated }) {
  return expressWaybillIsCreated === EXPRESS_WAYBILL_CREATING
}

export function isWaybillApplyError({ expressWaybillIsCreated }) {
  return expressWaybillIsCreated === EXPRESS_WAYBILL_CREATE_ERROR
}

// cutoff_batch_id这个不为0就是旧工厂，order_cutoff_batch_id这个不为0就是新mes
// 是否旧工厂订单
export function isOldFactoryOrder({ cutoffBatchId }) {
  return cutoffBatchId !== 0
}